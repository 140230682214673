<template>
  <div class="base-wrapper">
    <section class="title-container text-centered">
      <h1 class="title">
        Welcome to <span class="highlighted-text">DB</span>hubs Rocket League
      </h1>
      <p class="sub-title">Mentality, Coaching, and Community</p>
      <p class="text-regular">
        With a unique approach to improvement, we believe the best way to rank up is to enhance every part of your mind
        and body. We care about your growth in life and in Rocket League!
      </p>
    </section>
    <section class="tiles-wrapper text-centered">
      <div class="tiles-container">
        <router-link class="tile" to="/coaches">
          <div class="card">
            <div class="card-img">
              <img src="../assets/images/coaching.png" alt="Coaches">
            </div>
            <div class="card-description">
              <p class="card-title">Top Tier Coaching Team</p>
              <p class="card-info">
                Master your skills with <br>

              </p>
              <ul class="card-list highlighted-text">
                <li>
                  Ex-pros
                </li>
                <li>
                  E-sports coaches
                </li>
                <li>
                  Top 100 players
                </li>
                <li class="hidden">

                </li>
              </ul>
              <router-link class="card-button" to="/coaches">SEE COACHES</router-link>
            </div>
          </div>
        </router-link>
        <router-link class="tile" to="/about">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <div class="card">
              <div class="card-img">
                <img src="../assets/images/lecture.png" alt="Live Events">
              </div>
              <div class="card-description">
                <p class="card-title">10 + Live Events per Month</p>
                <p class="card-info">
                  Spend time with the Coaches and Community!
                </p>
                <ul class="card-list highlighted-text">
                  <li>
                    Live coaching
                  </li>
                  <li>
                    Q&A sessions
                  </li>
                  <li>
                    Mentality drop-ins
                  </li>
                  <li>
                    Tournaments
                  </li>
                </ul>
                <router-link class="card-button" to="/about">MORE INFO</router-link>
              </div>
            </div>
          </a>
        </router-link>
        <router-link class="tile" to="/join">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <div class="card">
              <div class="card-img">
                <img src="../assets/images/community.png" alt="Community">
              </div>
              <div class="card-description">
                <p class="card-title">First Two Weeks Free</p>
                <p class="card-info">
                  Sign up now to enjoy
                </p>
                <ul class="card-list highlighted-text">
                  <li>
                    Ranking up
                  </li>
                  <li>
                    Having more fun in RL than ever
                  </li>
                  <li>
                    Full access for <span style="color:var(--dbhubs-green)">ONLY $24.99</span> a month
                  </li>
                  <li class="hidden">

                  </li>
                </ul>
                <router-link class="card-button card-join-button" to="/join">JOIN NOW</router-link>
              </div>
            </div>
          </a>
        </router-link>
      </div>
    </section>

    <section class="join-section">
      <!-- <JoinButton></JoinButton> -->
    </section>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import JoinButton from '@/components/elements/JoinButton.vue';

@Options({
  components: {
    JoinButton,
  },
})

export default class HomeView extends Vue {
}
</script>

<style scoped>
@import url(../style/vars.css);
@import url(../style/view/general.css);
@import url(../style/modules/tiles.css);

a:link,
a:visited {
  text-decoration: none !important;
  color: inherit;
}

.text-centered {
  text-align: center;
}

.title-container {
  padding: 2rem;
}

.title {
  margin: 2rem 0;
  /* font-size: 2.5rem; */
  font-weight: bold;
  color: #333;
}



.sub-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #666;
}

.text-regular {}

.card-join-button {
  color: #333 !important;
  background-color: var(--dbhubs-green);
}

.card-join-button:hover {
  background-color: var(--dbhubs-green-neon);
}

.join-section {
  padding: 2rem 0;
}

.nav-link {}

.nav-link:hover {}

.shadow {
  border: 2px solid #333;
  box-shadow: 0.1em 0.1em 0.25em #333;
}


/* Responsive Design */
@media (max-width: 853px) {
  .base-wrapper {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  #Join {
    font-size: 1.5rem;
  }
}

@media (max-width: 570px) {
  .title {
    font-size: 1.8rem;
  }

  .sub-title {
    font-size: 1.2rem;
  }

  .list-title {
    font-size: 1.1rem;
  }

  .list-info {
    font-size: 0.8rem;
  }

  #Join {
    font-size: 1.25rem;
  }
}

@media (min-height: 600px) {
  .card {
    min-height: 77vh;
  }
}

@media (min-height: 800px) {
  .card {
    min-height: 62vh;
  }
}
</style>