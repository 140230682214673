<template>
  <div class="nav-container">
    <nav :class="isMenuOpen ? 'nav-dropdown-enabled__container' : ''">
      <div id="logo-container">
        <router-link to="/">
          <img src="../assets/logos/DBHubsTextLogo.png" id="logo-text">
        </router-link>
      </div>
      <button id="menuToggle" class="menu-toggle" @click="() => toggleMenu()">
        ☰
      </button>
      <div ref="dropdown" v-if="isMenuOpen">
        <transition name="dropdown">
          <div class="navbar">
            <ul class="dropdown-menu">
              <router-link @click="() => toggleMenu()" class="nav-link hover-color-nav" draggable="false"
                to="/">Home</router-link>
              <router-link @click="() => toggleMenu()" class="nav-link hover-color-nav" draggable="false"
                to="/coaches">Coaches</router-link>
              <router-link @click="() => toggleMenu()" class="nav-link hover-color-nav" draggable="false"
                to="/about">About</router-link>
              <router-link @click="() => toggleMenu()" class="nav-link hover-color-nav" draggable="false"
                to="/join">Join</router-link>
            </ul>
          </div>
        </transition>
      </div>
      <div :class="['link-container-wrapper']">
        <div class="link-container">
          <router-link class="nav-link hover-color-nav" draggable="false" to="/">Home</router-link>
        </div>
        <div class="separator">|</div>
        <div class="link-container">
          <router-link class="nav-link hover-color-nav" draggable="false" to="/coaches">Coaches</router-link>
        </div>
        <div class="separator">|</div>
        <div class="link-container">
          <router-link class="nav-link hover-color-nav" draggable="false" to="/about">About</router-link>
        </div>
        <div class="separator">|</div>
        <div class="link-container">
          <router-link class="nav-link hover-color-nav" draggable="false" to="/join">Join</router-link>
        </div>
      </div>
      <div class="flex-spacer"></div>
    </nav>
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'Header',
  setup() {
    const isMenuOpen = ref(false);

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    }

    const handleClickOutside = (event: MouseEvent) => {
      const element = document.getElementById('menuToggle') as HTMLElement;

      if (element && !element.contains(event.target as Node)) {
        isMenuOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      isMenuOpen,
      toggleMenu
    };
  },
});
</script>

<style scoped>
@import url(../style/vars.css);
@import url(../style/view/general.css);

.nav-container {
  width: 100%;
  margin: 0;
  z-index: 1000;
  /* overflow-y: auto; */
  /* max-height: 100vh; */
}

.navbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  transition: all 0.3s ease;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
}

.navbar-logo {
  font-size: 24px;
}

.menu-button {
  font-size: 16px;
  padding: 10px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.router-link-active {
  color: black;
}

.nav-link {
  margin: 0.5rem 0;
}

.nav-link:not(.router-link-active) {
  color: var(--dbhubs-gray);
}


.nav-link:not(.router-link-active):hover {
  color: var(--dbhubs-red) !important;
}

.dropdown-menu {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: var(--dbhubs-light);
  width: 100%;
  position: absolute;
  top: 60px;
  /* Adjust based on header height */
  left: 0;
  text-align: center;
}

.dropdown-menu li {
  padding: 10px;
  border-bottom: 1px solid #555;
}

.dropdown-menu li a {
  color: white;
  text-decoration: none;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.3s, transform 0.3s ease;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-10px);
  /* Animates dropdown from the bottom of the header */
}

nav {
  display: flex;
  background-color: var(--dbhubs-light);
  height: 4rem;
  min-height: 2.6rem;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
}

#logo-container {
  flex: 1;
}

#logo-text {
  height: 2rem;
  margin-left: 2rem;
  /* margin-top: 0.5rem; */
}

.link-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  flex-grow: 1;
}

.link-container {
  margin: 0 1rem;
}

.separator {
  margin: 0 0.5rem;
}

.nav-dropdown-enabled__container {}

.nav-link {
  text-decoration: none !important;
  color: #666;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  right: 1rem;
}

.flex-spacer {
  flex: 1;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  nav {
    flex-direction: column;
    padding-top: 32px;
    align-items: flex-start;
    justify-content: space-evenly;
    /* height: auto; */
    padding: 0.5rem;
    height: 1.5rem;
  }

  #logo-container {
    width: 100%;
    justify-content: space-between;
    margin-top: 0.6rem;
  }

  /* Initial state for the mobile menu */
  .link-container-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: flex-end;

    background-color: var(--dbhubs-light);
    text-align: center;

    padding: 0 1em;
    width: auto;
    border-radius: 0.5rem;

    /* Transition properties */
    max-height: 0;
    opacity: 0;
    transition: all 0.3s ease;
    transform: scaleY(0);
    transform-origin: 0 0;

  }

  /* When the menu is toggled on */
  .link-container-wrapper.show-menu {
    max-height: 1000px;
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 8px;
    transform: scaleY(1);
  }

  .link-container {
    margin: 0.5rem 0;
    width: 100%;
  }

  .separator {
    display: none;
  }

  .menu-toggle {
    display: flex;
  }
}

@media screen and (max-width: 480px) {
  .link-container-wrapper {
    font-size: 1.25rem;
  }
}

/* These address the nav link on which respective page you're on */
.router-link-active {
  color: black;
}

.nav-link:not(.router-link-active):hover {
  color: var(--dbhubs-red) !important;
}



.router-link-exact-active {}
</style>
