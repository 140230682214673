<template>
  <div class="outer-wrapper">
    <div class="container">
      <div class="footer-topper">
        <div class="logo-container">
          <router-link to="/" class="logo">
            <img src="../assets/logos/DBHubsTextLogo.png" id="logo-text">
          </router-link>

          <div class="socials-container">
            <a target="_blank" rel="noopener noreferrer" class="socials" href="https://discord.gg/dbhubsrl">
              <img class="socials-img" src="../assets/logos/socials/discord.png">
            </a>
            <a target="_blank" rel="noopener noreferrer" class="socials"
              href="https://www.instagram.com/dbhubsofficial/">
              <img class="socials-img"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/900px-Instagram_icon.png?20200512141346">
            </a>
            <a target="_blank" rel="noopener noreferrer" class="socials"
              href="https://youtube.com/@DBhubs?feature=shared">
              <img class="socials-img" src="../assets/logos/socials/yt.png">
            </a>
            <a target="_blank" rel="noopener noreferrer" class="socials"
              href="https://www.tiktok.com/@dbhubsofficial?_t=8m8rVRIApNS&_r=1">
              <img class="socials-img" src="../assets/logos/socials/tiktok.png">
            </a>
          </div>
        </div>
      </div>
      <div class="footer-content">
        <div>
          <h3 class="footer-category-title">Pages</h3>
          <div class="pages-wrapper">
            <router-link class="hover-color" to="/">Home</router-link>
            <router-link to="/coaches" class="hover-color">Coaches</router-link>
            <router-link to="/join" class="hover-color">Sign up</router-link>
            <router-link to="/faq" class="hover-color">Help / FAQ</router-link>
          </div>
        </div>
        <div class="other-container">
          <h3 class="footer-category-title">Socials</h3>
          <div>
            <a target="_blank" class="hover-color" rel="noopener noreferrer" href="https://discord.gg/dbhubsrl">Join the
              free section on our
              Discord!</a>
            <a target="_blank" class="hover-color" rel="noopener noreferrer"
              href="https://www.instagram.com/dbhubsofficial/">Follow us on
              Instagram</a>
            <a target="_blank" class="hover-color" rel="noopener noreferrer"
              href="https://www.youtube.com/@DBhubsRL">Subscribe to
              our YouTube</a>
            <a target="_blank" class="hover-color" rel="noopener noreferrer"
              href="https://www.tiktok.com/@dbhubs?lang=en">Subscribe on TikTok</a>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>&copy; 2024 DBhubs. All rights reserved.</p>

        <router-link to="/attributions" class="hover-color">Attributions</router-link>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
})
export default class Footer extends Vue { }
</script>

<style scoped>
@import url(../style/vars.css);
@import url(../style/view/general.css);

.outer-wrapper {
  width: 100%;
  background-color: var(--dbhubs-light);
}

.container {
  padding: 2rem 3rem;
}

.footer-topper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--dbhubs-gray);
}

.logo-container {
  display: flex;
  align-items: center;
}

#logo-text {
  height: 2rem;
}

.socials-container {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.socials {
  margin: 0 0.5rem;
}

.socials-img {
  width: 2rem;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  padding: 2rem 0;
}

.footer-category-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--dbhubs-primary);

}

.pages-wrapper,
.other-container div {
  display: flex;
  flex-direction: column;
}

.pages-wrapper a,
.other-container a {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  text-decoration: none;
  color: #666;
}

.footer-bottom {
  text-align: center;
  padding-top: 1rem;
  border-top: 1px solid var(--dbhubs-gray);
}

.footer-bottom p {
  margin: 0;
  font-size: 1rem;
  color: var(--dbhubs-dark);
}

.other-container {
  text-align: left;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-topper {
    flex-direction: column;
  }

  .footer-category-title {
    text-align: center;
  }

  .logo-container {
    margin-bottom: 1rem;
    text-align: center;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .other-container {
    margin-top: 2rem;

  }

  .footer-bottom {
    padding-top: 1rem;
  }

  .pages-wrapper {
    text-align: center;
  }

  .other-container {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .footer-category-title {
    font-size: 1.25rem;
  }

  .pages-wrapper router-link,
  .other-container a {
    font-size: 1rem;
  }



  .socials-img {
    width: 1.5rem;
  }

  #logo-text {
    height: 1.5rem;
  }
}
</style>
