import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Placeholder from '@/Placeholder.vue';
import { API_URL } from '@/data/consts';

const params = new URLSearchParams(window.location.search);
const affiliate = params.get('affiliate');
affiliate && localStorage.setItem('affiliate', affiliate);

fetch(`${API_URL}/track?affiliate=${affiliate}`).then((x) => {
  //   createApp(Placeholder).mount('#app');
  createApp(App).use(router).mount('#app');
});
